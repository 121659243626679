<template>
    <div>
        <SimpleTable ref="simpletable" formclass="Modal/form" endpoint="modals" :aditionalColumns="headers" :hideNew="!isAdmin" :hideEdit="!isAdmin" hideDelete updateTime="10">
            <template v-slot="data">
                <h1>Modal</h1>
                <v-divider class="mb-4" />
                <v-layout wrap class="ma-12">
                    <v-flex xs12 sm3>
                        <v-card color="primary">
                            <v-card-text class="white--text">
                                <div class="headline mb-2">
                                    <v-icon dark>functions</v-icon>
                                    {{ total(data.items) }}
                                </div>
                                Cadastradas
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 sm3>
                        <v-card color="primary">
                            <v-card-text class="white--text">
                                <div class="headline mb-2">
                                    <v-icon dark>functions</v-icon>
                                    {{ active(data.items) }}
                                </div>
                                Ativas
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 sm3>
                        <v-card color="primary">
                            <v-card-text class="white--text">
                                <div class="headline mb-2">
                                    <v-icon dark>functions</v-icon>
                                    {{ inUse(data.items) }}
                                </div>
                                Em Uso
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 sm3>
                        <v-card color="primary">
                            <v-card-text class="white--text">
                                <div class="headline mb-2">
                                    <v-icon dark>functions</v-icon>
                                    {{ inMaintenance(data.items) }}
                                </div>
                                Em Manutenção
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
                <v-divider class="mb-4" />
            </template>

            <template v-slot:extra-column="item">
                <!-- <v-chip v-if="item.inUse" class="ma-2" outlined color="error"> <v-icon left> error </v-icon>Em Uso</v-chip> -->
                <v-btn v-if="item.inUse" rounded color="warning" x-small dark @click="confirmRelease(item)"> Em Uso! Clique e libere. </v-btn>
                <v-chip v-else class="ma-2" color="success" outlined>
                    <v-icon left> done </v-icon>
                    Disponível
                </v-chip>
            </template>
        </SimpleTable>
        <v-dialog v-model="dialog" max-width="400">
            <v-card>
                <v-card-title class="text-h5"> Confirma a liberação da bateria? </v-card-title>

                <v-card-text v-if="itemRelease"> Modal: {{ itemRelease.plate || itemRelease.serialNumber }} </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="success darken-1" @click="release"> Confirmar </v-btn>

                    <v-btn color="error darken-1" @click="dialog = false"> Cancelar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
import { mapGetters } from "vuex";

export default {
    components: {
        SimpleTable,
    },

    data() {
        return {
            headers: [
                { text: this.$t("Disponibilidade"), value: "extra-column", align: "center", sortable: false },
                { text: this.$t("Habilitado"), value: "enable", align: "right", sortable: false },
                { text: this.$t("Tipo"), value: "type", align: "right", sortable: true },
                { text: this.$t("Modelo"), value: "model", align: "right", sortable: true },
                { text: this.$t("Placa"), value: "plate", align: "right", sortable: true },
                { text: this.$t("Serial"), value: "serialNumber", align: "right", sortable: true },
            ],
            isAdmin: false,
            dialog: false,
            itemRelease: null,
        };
    },

    mounted() {
        this.isAdmin = this.verifyRole("ADMIN");
    },

    computed: {
        ...mapGetters(["verifyRole"]),
    },

    methods: {
        total(items) {
            return Number.parseFloat(items.length).toFixed(0);
        },
        active(items) {
            return Number.parseFloat(items.reduce((sum, i) => (i.enable ? sum + 1 : sum), 0)).toFixed(0);
        },
        inUse(items) {
            return Number.parseFloat(items.reduce((sum, i) => (i.inUse ? sum + 1 : sum), 0)).toFixed(0);
        },
        inMaintenance(/*items*/) {
            return 0; //Number.parseFloat(items.reduce((sum, i) => (i.inUse ? sum + 1 : sum), 0)).toFixed(0);
        },
        confirmRelease(battery) {
            this.itemRelease = battery;
            this.dialog = true;
        },
        release() {
            this.$http
                .patch(`modals/${this.itemRelease.id}/release`)
                .then(() => {
                    this.itemRelease = null;
                    this.dialog = false;
                    this.$refs.simpletable.update();
                })
                .catch(() => this.$eventHub.$emit("msgError", this.$t("Erro ao liberar.")));
        },
    },
};
</script>